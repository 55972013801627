export enum LeftPanelItem {
    HOME = 'insights-home',
    FAVOURITES = 'favourites',
    LIVEBOARDS = 'liveboards',
    ANSWERS = 'answers',
    DOCUMENTS = 'documents',
    CREATED_BY_ME = 'created-by-me',
    MONITOR_ALERTS = 'monitor-alerts',
    SPOTIQ_ANALYSIS = 'spotiq-analysis',
    HELP = 'insights-help',
    CHAT_WITH_SUPPORT = 'insights-chat-with-support',
}

import {
    ApplicationMetricsContextStates,
    ApplicationMetricsContextStatesType,
} from '@thoughtspot/blink-context';

export const BlinkV2MetricsContextStates: ApplicationMetricsContextStatesType = {
    ...ApplicationMetricsContextStates,
    VIEW: 'VIEW',
    DEEPLINK_PAGE: 'DEEPLINK_PAGE',
    COMS_CONNECT_PAGE: 'COMS_CONNECT_PAGE',
    ANSWER_PAGE: 'ANSWER_PAGE',
    ANSWER_PAGE_V2: 'ANSWER_PAGE',
    WORKSHEET: 'WORKSHEET',
    HOME_PAGE: 'HOME_PAGE',
    INSIGHTS_HOME: 'INSIGHTS_HOME',
    SAVED_ANSWER: 'SAVED_ANSWER',
    SAVED_ANSWER_V2: 'SAVED_ANSWER',
    EXPERT_REQUESTS: 'EXPERT_REQUESTS',
    PINBOARD_REPORTS: 'PINBOARD_REPORTS',
    SCHEDULE_REPORT: 'SCHEDULE_REPORT',
    SLACK_REGISTER: 'SLACK_REGISTER',
    USER_PREFERENCES: 'USER_PREFERENCES',
    DATA_MANAGEMENT: 'DATA_MANAGEMENT',
    TABLE_DETAIL: 'TABLE_DETAIL',
    EMBRACE_TABLE: 'EMBRACE_TABLE',
    EMBRACE_REMAPPING: 'EMBRACE_REMAPPING',
    EMBRACE_CONNECTION_CONFIRMATION: 'EMBRACE_CONNECTION_CONFIRMATION',
    SNAPSHOT_VIEWER: 'SNAPSHOT_VIEWER',
    SCHEMA_VIEWER: 'SCHEMA_VIEWER',
    SCHEMA_VIEWER_DIALOG: 'SCHEMA_VIEWER_DIALOG',
    LEAF_LEVEL_DATA_VIEWER: 'LEAF_LEVEL_DATA_VIEWER',
    INSIGHTS_JOB_VIEWER: 'INSIGHTS_JOB_VIEWER',
    INSIGHT_CARD_ANSWER: 'INSIGHT_CARD_ANSWER',
    PINBOARD_CARD_ANSWER: 'PINBOARD_CARD_ANSWER',
    SPOTIQ_CARD_ANSWER: 'SPOTIQ_CARD_ANSWER',
    ADMIN_PAGE: 'ADMIN_PAGE',
    EMBED_PAGE: 'EMBED_PAGE',
    NON_EMBED_ACCESS: 'NON_EMBED_ACCESS',
    NON_LOGGEDIN: 'NON_LOGGEDIN',
    CLUSTER_STATUS: 'CLUSTER_STATUS',
    DEBUG_PAGE: 'DEBUG_PAGE',
    BLANK_PAGE: 'BLANK_PAGE',
    ANSWERS_LIST: 'ANSWERS_LIST',
    PINBOARDS_LIST: 'PINBOARDS_LIST',
    PINBOARD_PAGE: 'PINBOARD_PAGE',
    INSIGHT_PAGE: 'INSIGHT_PAGE',
    IMPORT_DATA: 'IMPORT_DATA',
    IMPORT_CSV: 'IMPORT_CSV',
    IMPORT_TSL: 'IMPORT_TSL',
    TSL_EDITOR: 'TSL_EDITOR',
    SPOTAPP: 'SPOTAPP',
    SPOTAPP_EXPORT: 'SPOTAPP_EXPORT',
    SQL_VIEW: 'SQL_VIEW',
    CONFIGURE_SYNC_MODAL: 'CONFIGURE_SYNC_MODAL',
    DESTINATION_SYNC_DELETE: 'DESTINATION_SYNC_DELETE',
    DESTINATION_SYNC_MANAGE_PIPELINE: 'DESTINATION_SYNC_MANAGE_PIPELINE',
    DESTINATION_SYNC_EDIT: 'DESTINATION_SYNC_EDIT',
    WORKSHEET_UPLOAD: 'WORKSHEET_UPLOAD',
    SLACK_AUTH: 'SLACK_AUTH',
    CREATE_SCHEMA: 'CREATE_SCHEMA',
    IMPORT_DATA_SOURCE: 'IMPORT_DATA_SOURCE',
    CHOOSE_SOURCES: 'CHOOSE_SOURCES',
    LOGIN_PAGE: 'LOGIN_PAGE',
    PRINT_PAGE: 'PRINT_PAGE',
    ONBOARDING: 'ONBOARDING',
    ACCOUNT_ACTIVATION: 'ACCOUNT_ACTIVATION',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    ERROR_PAGE: 'ERROR_PAGE',
    EUREKA: 'EUREKA',
    CREATE_AI_ANSWER: 'CREATE_AI_ANSWER',
    AI_ANSWER: 'AI_ANSWER',
    TABLE_JOIN: 'TABLE_JOIN',
    SEARCH_ASSIST: 'SEARCH_ASSIST',
    OAUTH_REDIRECT: 'OAUTH_REDIRECT',
    SETUP: 'SETUP',
    MONITOR_LIST: 'MONITOR_LIST',
    MONITOR_RULE_LIST: 'MONITOR_RULE_LIST',
    MONITOR_DETAIL: 'MONITOR_DETAIL',
    AUTO_WORKSHEET: 'AUTO_WORKSHEET',
    AUTO_ANSWER: 'AUTO_ANSWER',
    MONITOR: 'MONITOR',
    MONITOR_V2: 'MONITOR_V2',
    CUSTOM_CALENDAR_V2: 'CUSTOM_CALENDAR_V2',
    PINBOARD_V2: 'PINBOARD_PAGE',
    BUSINESS_DATA_MODEL: 'BUSINESS_DATA_MODEL',
    USAGE_STATISTICS: 'USAGE_STATISTICS',
    PINBOARD_SCHEDULE: 'PINBOARD_SCHEDULE',
    TEAMS: 'TEAMS',
    SEEKWELL_AUTH_REDIRECT: 'SEEKWELL_AUTH_REDIRECT',
    VISUAL_TEST_PAGE: 'VISUAL_TEST_PAGE',
    EMBED_TEST_PAGE: 'EMBED_TEST_PAGE',
    ORGS: 'ORGS',
    REQUEST_ACCESS: 'REQUEST_ACCESS',
    KPI_ANOMALIES: 'KPI_ANOMALIES',
    EDIT_A_COPY: 'EDIT_A_COPY',
    SAML_COMPLETE: 'SAML_COMPLETE',
    SEARCH_BAR_EMBED: 'SEARCH_BAR_EMBED',
    WORKSHEET_ACTIONS: 'WORKSHEET_ACTIONS',
    EVERYWHERE_STANDALONE_PAGE: 'EVERYWHERE_STANDALONE_PAGE',
    EVERYWHERE_PAGE: 'EVERYWHERE_PAGE',
    PURCHASE: 'PURCHASE',
    SPOTIQ_ANALYSIS: 'SPOTIQ_ANALYSIS',
    FAVORITE: 'FAVORITE',
    CREATED_BY_ME: 'CREATED_BY_ME',
    EMBRACE: 'EMBRACE',
    EMBRACE_CONNECTION: 'EMBRACE_CONNECTION',
    CONV_ASSIST: 'CONV_ASSIST',
    CAPTAIN: 'CAPTAIN',
    PRESENT_MODE: 'PRESENT_MODE',
    EDGE: 'EDGE',
    NEWONBOARDING: 'NEWONBOARDING',
    DOC_SEARCH: 'DOC_SEARCH',
} as const;
